<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="financialEstimateModel"
    label-width="100px"
    >
    <el-form-item label="代码：" prop="financialEstimateCode">
      <el-input
        type="text"
        v-model="financialEstimateModel.financialEstimateCode">
      </el-input>
    </el-form-item>
    <el-form-item label="名称：" prop="financialEstimateName">
      <el-input
        type="text"
        v-model="financialEstimateModel.financialEstimateName">
      </el-input>
    </el-form-item>
    <el-form-item label="金额：" prop="financialEstimateAmount">
      <el-input-number
        v-model="financialEstimateModel.financialEstimateAmount"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <template v-if="!financialEstimateModel.resourceId">
      <el-form-item label="所属公司：">
        <el-select v-model="companyGuid" clearable>
          <el-option
            v-for="company in companys"
            :key="company.companyGuid"
            :label="company.companyName"
            :value="company.companyGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属项目：" prop="projectGuid">
        <el-select v-model="financialEstimateModel.projectGuid" clearable>
          <el-option
            v-for="project in projects"
            :key="project.projectGuid"
            :label="project.projectName"
            :value="project.projectGuid"
            >
          </el-option>
        </el-select>
      </el-form-item>
    </template>
    <el-form-item label="编号：" prop="financialEstimateId">
      <el-input-number
        v-model="financialEstimateModel.financialEstimateId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="父节点：" prop="parentId">
      <my-select-tree
        v-if="nodes[0].children && nodes[0].children.length"
        v-model="financialEstimateModel.parentId"
        nodeKey="financialEstimateId"
        labelKey="financialEstimateName"
        :nodes="nodes"
        :props="props">
      </my-select-tree>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="financialEstimateModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import FinancialEstimateModel from '@/model/FinancialEstimateModel'
import financialEstimateService from '@/services/financialEstimateService'
import MySelectTree from '@/components/MyTree/MySelectTree'
import GetProject from '@/mixins/GetProject'

export default {
  name: 'FinancialEstimateForm',
  components: {
    MySelectTree
  },
  mixins: [
    GetProject
  ],
  props: {
    financialEstimateModel: {
      type: FinancialEstimateModel,
      default () {
        return new FinancialEstimateModel()
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.financialEstimateModel.projectGuid = ''
      this.projects = []
      this.nodes = [
        new FinancialEstimateModel({ financialEstimateId: 0, financialEstimateName: '根节点' })
      ]
      newVal && this._getProjects(newVal)
    },
    projectGuid (newVal) {
      if (newVal) {
        this._getNodes()
      }
    }
  },
  data () {
    return {
      companyGuid: '',
      nodes: [
        new FinancialEstimateModel({ financialEstimateId: 0, financialEstimateName: '根节点' })
      ],
      props: {
        label: 'financialEstimateName',
        children: 'children'
      },
      rules: {
        financialEstimateName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        financialEstimateCode: [
          {
            required: true,
            message: '请输入代码',
            trigger: 'blur'
          }
        ],
        projectGuid: [
          {
            required: true,
            message: '请选择所属项目',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    projectGuid () {
      return this.financialEstimateModel.projectGuid
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getNodes () {
      financialEstimateService.list({}, { projectGuid: this.projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.$set(this.nodes[0], 'children', res.data.data)
            console.log(this.nodes)
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err.request.response)
          this.$message({
            type: 'error',
            message: '加载数据错误！'
          })
        })
    }
  },
  mounted () {
    if (this.projectGuid) {
      this._getNodes()
    }
  }
}
</script>
