import utility from '@/common/utility'
import auth from '@/common/auth'

export default class FinancialEstimateModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.financialEstimateGuid
      this.financialEstimateGuid = props.financialEstimateGuid
      this.financialEstimateName = props.financialEstimateName
      this.financialEstimateAmount = props.financialEstimateAmount
      this.projectGuid = props.projectGuid
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.financialEstimateCode = props.financialEstimateCode
      this.financialEstimateId = props.financialEstimateId
      this.parentId = props.parentId
      this.staffName = props.staffName
      this.children = props.children
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.financialEstimateGuid) this.financialEstimateGuid = ''
    if (!this.financialEstimateName) this.financialEstimateName = ''
    if (!this.financialEstimateAmount) this.financialEstimateAmount = 0
    if (!this.projectGuid) this.projectGuid = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.financialEstimateCode) this.financialEstimateCode = ''
    if (!this.financialEstimateId) this.financialEstimateId = 0
    if (!this.parentId) this.parentId = 0
    if (!this.staffName) this.staffName = ''
    if (!this.children) this.children = []
  }

  generatePrimaryKey () {
    this.financialEstimateGuid = utility.getUuid()
    this.resourceId = this.financialEstimateGuid
  }
}
