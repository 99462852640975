<template>
  <div class="financialEstimate-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      class="mt-3"
      :data="financialEstimateList"
      border
      default-expand-all
      :height="tableHeight"
      header-cell-class-name="bg-info text-light"
      row-key="financialEstimateId"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="financialEstimateCode" width="100" header-align="center" align="center" label="代码"></el-table-column>
      <el-table-column prop="financialEstimateName" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column prop="financialEstimateAmount" header-align="center" align="center" label="金额"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column prop="createTime" width="160" :formatter="timeFormatter" header-align="center" align="center" label="创建时间"></el-table-column>
      <el-table-column width="90" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <financialEstimate-form
        ref="financialEstimateForm"
        :financialEstimateModel="financialEstimateModel">
      </financialEstimate-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import FinancialEstimateForm from '@/views/FinancialEstimateManage/FinancialEstimateForm'
import FinancialEstimateModel from '@/model/FinancialEstimateModel'
import financialEstimateService from '@/services/financialEstimateService'
import PageOperation from '@/mixins/PageOperation'
import GetProject from '@/mixins/GetProject'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'FinancialEstimateIndex',
  mixins: [
    GetProject,
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    FinancialEstimateForm
  },
  data () {
    return {
      total: 0,
      financialEstimateList: [],
      financialEstimateModel: new FinancialEstimateModel(),
      formDialogTitle: '',
      searchModel: {
        financialEstimateName: '',
        projectGuid: '',
        companyGuid: ''
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    }
  },
  methods: {
    handleSearchClick () {
      this._getFinancialEstimateList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加概算信息'
      this.financialEstimateModel = new FinancialEstimateModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑概算信息'
      this.financialEstimateModel = new FinancialEstimateModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _financialEstimateModel = new FinancialEstimateModel(row)
          financialEstimateService.delete(_financialEstimateModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getFinancialEstimateList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.financialEstimateForm.validate()(valid => {
        if (valid) {
          if (!this.financialEstimateModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getFinancialEstimateList () {
      financialEstimateService.list(new PageInfo({}), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.financialEstimateList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      financialEstimateService.add(this.financialEstimateModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getFinancialEstimateList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      financialEstimateService.edit(this.financialEstimateModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getFinancialEstimateList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getFinancialEstimateList()
    this.refreshMethod = this._getFinancialEstimateList
  }
}
</script>
